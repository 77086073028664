var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"background-color":"white"},attrs:{"fluid":""}},[_c('h2',{staticClass:"mt-4"},[_vm._v("Үдийн xоолны бүртгэл")]),_c('v-tabs',{staticClass:"mt-10",model:{value:(_vm.xSelectedTab),callback:function ($$v) {_vm.xSelectedTab=$$v},expression:"xSelectedTab"}},_vm._l((_vm.userData.children),function(child,cIndex){return _c('v-tab',{key:'1child' + cIndex,class:{ 'active-tab': _vm.xSelectedTab === cIndex }},[_vm._v(" "+_vm._s(child.FIRST_NAME)+" ("),_c('span',[_vm._v(_vm._s(child.STUDENT_GROUP_NAME))]),_vm._v(" ) ")])}),1),_c('v-tabs-items',{staticStyle:{"background-color":"white"},on:{"click":function($event){return _vm.forceRerender()}},model:{value:(_vm.xSelectedTab),callback:function ($$v) {_vm.xSelectedTab=$$v},expression:"xSelectedTab"}},_vm._l((_vm.userData.children),function(child,cIndex){return _c('v-tab-item',{key:'2child' + cIndex},[_c('v-row',[_c('v-col')],1),_c('v-col',[(_vm.calendarDates && _vm.renderComponent)?_c('v-data-table',{staticClass:"mt-10",attrs:{"items":_vm.calendarDates},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.index + 1))]),_c('td',[_vm._v(" "+_vm._s(props.item.year)+"-"+_vm._s(props.item.month)+"-"+_vm._s(props.item.day)+" ")]),_c('td',[_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(props.item.garagName))])]),_c('td',[_c('span',{style:(_vm.getAtt(props.item, child, false)
                      ? ''
                      : 'background-color: yellow')},[_vm._v(_vm._s(_vm.getAtt(props.item, child, false) ? "Ирц бүртгэсэн" : "Ирц бүртгээгүй"))])]),_c('td',[_c('span',{style:(_vm.getAtt(props.item, child, true) != 'Ирсэн'
                      ? 'background-color: yellow'
                      : '')},[_vm._v(_vm._s(_vm.getAtt(props.item, child, true)))])]),_c('td',[_vm._v("1000₮")]),_c('td',[(_vm.getFullStatus(props.item, child).reported)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.getFullStatus(props.item, child).text)+" ")]):_vm._e(),(_vm.getFullStatus(props.item, child).disabled)?_c('v-btn',{staticStyle:{"color":"grey"},attrs:{"elevation":"0","text":_vm.getFullStatus(props.item, child).noBorder,"small":""},on:{"click":function($event){return _vm.requestChangeInStatus(props.item, child, true)}}},[_vm._v(_vm._s(_vm.getFullStatus(props.item, child).text)+" ")]):_c('v-btn',{staticStyle:{"color":"green"},attrs:{"elevation":"0","text":_vm.getFullStatus(props.item, child).noBorder,"small":""},on:{"click":function($event){return _vm.requestChangeInStatus(props.item, child)}}},[_vm._v(_vm._s(_vm.getFullStatus(props.item, child).text)+" ")])],1),_c('td',[_c('v-btn',{style:(_vm.getIrzConfirmation(props.item, child)
                      ? 'color: green; '
                      : 'color: red; '),attrs:{"elevation":"0","x-small":""},on:{"click":function($event){return _vm._confirmIrz(props.item, child)}}},[_vm._v(_vm._s(_vm.getIrzConfirmation(props.item, child) ? "Ирц баталгаажсан" : "Ирц баталгаажуулаx"))])],1),_c('td',{staticClass:"blue--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getSelectedDaysFoodData(props.item)}}},[_vm._v(" Цэс xараx ")]),_c('td',{on:{"click":function($event){return _vm._parentComment()}}},[_c('v-btn',{staticStyle:{"background-color":"#fce4ec","color":"red"},attrs:{"elevation":"0","x-small":""}},[_vm._v(" Гомдол мэдэгдэх ")])],1)])]}}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.showFoodCalendarDialog),callback:function ($$v) {_vm.showFoodCalendarDialog=$$v},expression:"showFoodCalendarDialog"}},[(_vm.cookingCalendars && _vm.cookingCalendars.length > 0)?_c('v-card',[_c('v-card-title',[_c('h1',[_vm._v(" "+_vm._s(_vm.cookingCalendars[0].selectedCalendarDate.year)+"-"+_vm._s(_vm.cookingCalendars[0].selectedCalendarDate.month)+"-"+_vm._s(_vm.cookingCalendars[0].selectedCalendarDate.day)+" ")])]),_c('v-card-actions',{staticClass:"d-flex flex-column align-start px-6 pb-4 mt-n4"},_vm._l((_vm.cookingCalendars),function(calendar,cindex){return _c('v-row',{key:'calendar' + cindex,attrs:{"no-gutters":""}},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v(_vm._s(calendar.selectedXeelj.name)+":")]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(calendar.selectedFood.name.slice( 3, calendar.selectedFood.name.length )))])])}),1)],1):_c('v-card',{attrs:{"height":"200"}},[_c('v-card-title',{staticClass:"d-flex flex-row justify-center"},[_vm._v("Under construction")])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }